import styled from "@emotion/styled";

export const ButtonContainer = styled.div`
	display: block;
	width: 100%;
	text-align: center;
`;

export const ButtonContain = styled.div`
	display: inline-block;
	margin-right: 10px;
	margin-left: 10px;
	vertical-align: top;
`;

export const ModalDiv = styled.div`
	width: 1080px;
	display: block;
	height: 570px;
	padding-top: 28px;
	position: relative;
	box-sizing: border-box;
	max-width: 100%;
`;

export const UnsavedLocations = styled.div`
	display: block;
	width: 100%;
	height: 370px;
	overflow-x: hidden;
	overflow-y: auto;
	padding-top: 15px;
	padding-bottom: 5px;
	box-sizing: border-box;
`;

export const ModalClose = styled.div`
	width: 40px;
	height: 40px;
	border-radius: 40px;
	text-align: center;
	position: absolute;
	right: -20px;
	top: 20px;
	line-height: 48px;
	box-sizing: border-box;
	cursor: pointer;
`;

export const PopoverIcon = styled.div`
	transition: transform 5s;
	transform: rotate(0deg);
	-o-transition: transform 0.3s;
	-ms-transition: transform 0.3s;
	-moz-transition: transform 0.3s;
	-webkit-transition: transform 0.3s;
	display: inline-block;
	margin-left: 10px;
	transition: transform 0.3s;
`;

export const MenuDivider = styled.hr<any>`
	border-top: 1px solid ${(props) => props.color};
	opacity: 0.1;
	width: 100%;
	margin: 5px 0px;
`;

export const SearchBox = styled.div`
	display: block;
	width: 100%;
	padding: 30px 20px 32px 20px;
	margin-top: 50px;
	box-sizing: border-box;
	height: auto;
	border-radius: 2px 2px 0px 0px;
`;

export const FindALocation = styled.div`
	display: inline-block;
	width: calc(100% - 400px);
	vertical-align: top;
`;
export const Within = styled.div`
	display: inline-block;
	width: 220px;
	margin-left: 10px;
	vertical-align: top;
`;
export const Apply = styled.div`
	display: inline-block;
	width: 160px;
	margin-left: 10px;
	vertical-align: top;
`;

export const ApplyButton = styled.div`
	display: block;
	width: 100%;
	height: 40px;
	box-sizing: border-box;
	margin-top: 31px;
	line-height: 38px;
	box-sizing: border-box;
	border-radius: 42px;
	text-align: center;
`;

export const FilterBadge = styled.div`
	display: inline-block;
	padding: 4px 31px 4px 15px;
	position: relative;
	margin-right: 10px;
	margin-bottom: 10px;
	white-space: nowrap;
	border-radius: 30px;
`;

export const FilterBadgeIcon = styled.div`
	display: block;
	position: absolute;
	right: 10px;
	top: 6px;
	height: 12px;
	width: 12px;
`;

export const ClearFilters = styled.div`
	display: inline-block;
	margin-left: 10px;
	padding-left: 37px;
	vertical-align: middle;
	padding-top: 2px;
	cursor: pointer;
	height: 24px;
	width: auto;
	white-space: no-wrap;
	position: relative;
`;

export const ClearFiltersIcon = styled.div`
	display: block;
	position: absolute;
	left: 0px;
	top: 0px;
	height: 24px;
	width: 24px;
	border-radius: 24px;
	line-height: 23px;
	text-align: center;
`;

export const ResultContainer = styled.div`
	display: block;
	width: 100%;
	height: auto;
`;

export const Filters = styled.div`
	display: block;
	min-height: 70px;
	width: 100%;
	padding: 20px 20px 0px 20px;
	box-sizing: border-box;
`;

export const LeftPane = styled.div`
	display: inline-block;
	width: 43%;
	vertical-align: top;
	height: 870px;
	padding: 15px 0px 20px 0px;
	box-sizing: border-box;
`;

export const RightPane = styled.div`
	display: inline-block;
	width: 57%;
	vertical-align: top;
	height: 870px;
`;

export const LocationsFound = styled.div`
	display: block;
	width: 100%;
	height: 35px;
	margin-bottom: 5px;
	padding: 0px 15px 0px 15px;
`;

export const LocationsList = styled.div`
	display: block;
	width: 100%;
	height: calc(100% - 55px);
	overflow-x: hidden;
	overflow-y: auto;
`;

export const LocationsListItem = styled.div`
	display: block;
	width: 100%;
	padding: 20px 15px 20px 15px;
	box-sizing: border-box;
	height: 230px;
`;

export const LocationListItemImage = styled.div`
	display: inline-block;
	width: 140px;
	height: 100%;
	margin-right: 20px;
	vertical-align: top;
	overflow: hidden;
	img {
		height: 190px;
		width: 140px;
		object-fit: cover;
	}
`;
export const LocationListItemText = styled.div`
	display: inline-block;
	width: calc(100% - 160px);
	vertical-align: top;
	height: 100%;
`;

export const FauxButtonLink = styled.div`
	display: inline-block;
	height: 24px;
	white-space: nowrap;
	vertical-align: top;
	cursor: pointer;
`;

export const FauxIcon = styled.div`
	display: inline-block;
	width: 24px;
	border-radius: 24px;
	height: 24px;
	line-height: 26px;
	text-align: center;
	margin-right: 13px;
	vertical-align: middle;
`;

export const Spacer = styled.div`
	height: 12px;
	width: 100%;
	display: block;
`;

export const MobileFilterBadge = styled.span`
	display: inline-block;
	width: auto;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 30px;
	line-height: 30px;
	margin-right: 10px;
	border-radius: 30px;
	position: relative;
	margin-bottom: 10px;
	box-sizing: border-box;
	padding: 0 34px 0 10px;
	white-space: nowrap;
`;

export const MobileOverlay = styled.div`
	position: fixed;
	top: 0;
	display: block;
	height: 100%;
	width: 100%;
	z-index: 99;
	left: -100%;
	transition: left 0.5s ease-in-out;
`;

export const MobileOverlayHeader = styled.div`
	display: block;
	width: 100%;
	height: 50px;
	position: relative;
`;

export const MobileOverlayHeaderClose = styled.div`
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	box-sizing: border-box;
	width: 51px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	cursor: pointer;
`;

export const MobileOverlayBody = styled.div`
	display: block;
	width: 100%;
	height: calc(100% - 110px);
	overflow-y: auto;
	overflow-x: hidden;
	padding: 25px 20px 20px 20px;
	box-sizing: border-box;
`;
